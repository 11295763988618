.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
  }
  
  .loading-text {
    font-size: 20px;
    color: #333;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  


  /* if (isLoading) {
    // Show loading UI
    return <div className="loading-container">
        <div className="loading-spinner"></div>
        <div className="loading-text">Loading...</div>
    </div>;
} */