@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Roboto+Mono:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;1,100;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@100;300;400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply px-5 py-3 bg-white text-bg-dark rounded-full font-md capitalize max-w-fit font-Roboto;
  }

  .black {
    background: #000!important;
  }

  .link {
    @apply font-md transition-all capitalize;
  }

  .section {
    @apply pt-[6rem] w-screen h-screen flex items-center justify-center overflow-hidden;
  }


  .section2 {
    @apply font-Lato pt-[6rem] flex items-center justify-center text-bg-dark;
  }

  .context {
    @apply p-5 flex w-4/5 h-full items-center justify-center sm:w-full;
  }


  .h1 {
    @apply text-[2rem] font-Lato capitalize font-b sm:text-[1.5rem];
  }

  .p1 {
    @apply font-Lato text-textGray font-md;
  }

  /* .sub {
    @apply w-[35vw] h-[10rem] flex-wrap border-[3px] p-2 rounded border-gray overflow-hidden flex flex-col gap-5 justify-between cursor-pointer hover:shadow-2xl  ;

  } */

  @media (min-width: 200px) and (max-width: 900px) {
   
 #hero-context{
  height: 120vh;
  padding-bottom: 180px;
  margin-bottom: 300px;
  margin-top: 290px;
 }

 #hero-image{
 
 }


 }



  .sub {
    /* @apply w-[30vw] h-[15rem] flex-wrap p-2 rounded-lg overflow-hidden flex flex-col gap-5 justify-between cursor-pointer; */
    @apply w-[30vw] h-[15rem] flex-wrap p-2 overflow-hidden flex flex-col gap-5 justify-between cursor-pointer;
    border: 3px solid rgba(255, 255, 255, 0.8);
    box-shadow: rgba(f,f,f,0.8) 0px 4px 12px; /* Initial box shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adding the transition effect for scaling and box shadow */
  }
  
  /* Scale up and add box shadow on hover */
  .sub:hover {
    box-shadow: 3px 3px 0px 2px rgb(255 255 255); /* Box shadow on hover */
    transform: scale(1.03);
  }

  @media (min-width: 200px) and (max-width: 900px) {
    .sub {
      width: 95%; /* Adjust the width to your preference for mobile devices */
      margin: auto;
    }
  }
  
  /* .sub #mobile-icon {
    width: 6rem; 
    height:5rem;
  } */

  .sub .icon {
    width: 5rem; /* Adjust the font size for the icon */
    height:4rem;
  }



  /* Reduce the gap between icon and h3 */
.sub .h3 {
  margin-top: -1.25rem;
}


.why-choose {
  border: 3px solid rgba(255, 255, 255, 0.8);
  box-shadow: rgba(0,0,0,0.8) 0px 4px 12px; /* Initial box shadow */
  transform: scale3d(1, 1, 1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adding the transition effect for scaling and box shadow */
}

/* Scale up and add box shadow on hover */
.why-choose:hover {
  /* box-shadow: 6px 6px 0px rgb(255 254 254);  */
  /* transform: translateY(-10px); */
  box-shadow: 3px 3px 0px 2px rgb(255 255 255);
  transform: scale3d(1.03, 1.03, 1);
}

/*footer copyrights align center*/
.copyright {
  justify-content: center !important;
  padding-left: 6px;
  padding-right: 15px;
}

.mxma {
color: rgb(128, 142, 155);
}

.submenu {
  /* padding-top: 5px;
  padding-right: 55px; */
  padding-left: 0px;
  /* padding-bottom: 0px; */
  padding-top: 11px;
    padding-bottom: 10px;
}

.submenulist {
  background: gainsboro;
  color: #000;
  padding-top: 5px;
  padding-bottom: 5px;
}
.submenulisthover {
  /* background: gainsboro; */
  /* color: #000; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.listtem {
 
    padding-left: 15px;
    padding-right: 30px;
}

/* #tesla{
  height: 505px;
} */

@media (min-width: 200px) and (max-width: 900px) {
  
  #tesla{
    height: 300px;
    width: 100%;
    padding: 0;
    margin: 0;
  }
}


  .h3 {
    @apply font-Roboto text-[1rem] uppercase font-b;
  }

  .p2 {
    @apply font-Lato text-textGray text-[16px];
  }

  .icon {
    @apply text-[2rem] text-white w-[2rem] h-[2rem];
  }

  .icon2 {
    @apply text-[2rem] text-gray;
  }

  .icon3 {
    @apply drop-shadow text-[1.2rem];
  }

  .reverse {
    @apply flex-row-reverse;
  }

}

.context-footer {
  @apply  flex w-4/5 h-full items-center justify-center sm:w-full pl-4 mt-5;
}

.list-custom li {
  position: relative;
  transition: all 0.3s ease-out 0s;
  padding-bottom: 10px;
  font-weight: 500;
}

.list-custom li::before {
  content: "\25BA";
  color: white;
  font-family: "Font Awesome 5 Free";
  display: inline-block; /* Use display: inline-block instead of position: absolute */
  width: 20px; /* Set the width of the inline-block to adjust the space for the emoji */
  text-align: center; /* Center the emoji horizontally */
  
}

.list-custom li:hover {
  margin-left: 2px; /* Adjust the margin to create a hover effect */
  color: gray;
}

.repair-parts-img {
  padding-bottom: 15vh;
}


@media (min-width: 200px) and (max-width: 900px) {
  .repair-parts-img {
    padding-bottom: 1vh;
  }
  
}




